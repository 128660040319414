<template>
    <div class="header animate__animated animate__fadeInDown">
        <router-link to="/">
            <!-- <img :src="logo" alt=""> -->
            <i class="iconfont icon-16gl-Q"></i>
        </router-link>
        <router-link v-if="!isShow" to="/login">
            <div class="login_btn">
                登录/注册
            </div>
        </router-link>
        <div v-else class="lofin_info">
            <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">
                    <strong>{{userInfo.username}}</strong>
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown" placement="bottom-start">
                    <el-dropdown-item command="addArticle">发布文章</el-dropdown-item>
                    <el-dropdown-item command="signOut">退出</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>
<script>
import { api, baseURL} from '@/utils/api'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { get, post } from '@/utils/require'
export default {
    name:'Header',
    computed:{
        ...mapState(['userInfo']), 
    },
    data(){
        return {
            logo:require('../assets/images/logo.png'),
            isShow:false,
            username:''
        }
    },
    created(){
        
        
        // 如果刷新重新请求info接口 存入vuex
        if(!this.userInfo.id){
            // this.$store.commit('loginUserInfo');
            this.getUserInfo();
        }
        this.getInfo();
    },
    watch:{

    },
    methods:{
        ...mapMutations(['getUserInfo']),
        getInfo(){
            let user = this.userInfo;
            // console.log(user,this.$store.state.userInfo,66666)
            if(!user){
                this.isShow = false;
                // this.username = this.$store.state.userInfo.username;
            }else{
                // this.username = this.$store.state.userInfo.username;
                // console.log(this.$store.state.userInfo.username)
                this.isShow = true;
            }
        },
        handleCommand(command){
            console.log(command)
            if(command == 'signOut'){
                localStorage.removeItem('userInfo');
                sessionStorage.removeItem('token');
                this.$router.push('/login');
            }else if(command == 'addArticle'){
                this.$router.push('/createArticle');
            }
        }
    }
}
</script>

<style lang="stylus" scoped>
.header
    height 48px
    width 90%
    position fixed
    top 0
    left 0
    background-color rgba(255,255,255,0.3)
    z-index 13
    display flex
    justify-content space-between
    align-items center
    padding 0 5%
    border-bottom 1px solid #ccc
    a
        font-size 16px
        color #fff
        i
            font-size 36px
            color #fff
    img 
        width 40px

.lofin_info
    span 
        color #fff
        display flex
        align-items center
        i
            font-size 20px
</style>