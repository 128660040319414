import axios from 'axios'
import { Message } from 'element-ui';
// 环境的切换
// if (process.env.NODE_ENV == 'development') {    
//     axios.defaults.baseURL = 'https://www.baidu.com';} 
// else if (process.env.NODE_ENV == 'debug') {    
//     axios.defaults.baseURL = 'https://www.ceshi.com';
// } 
// else if (process.env.NODE_ENV == 'production') {    
//     axios.defaults.baseURL = 'https://www.production.com';
// }

const baseUrl = 'http://localhost:3000';

//创建axios实例
const axiosInstance = axios.create({
    baseURL:baseUrl,
    withCredentials:false,  //是否允许携带cookie
    timeout:9000,
    headers: {
      /*
      post的content-type三种类型：


        Content-Type: application/json
        对于axios，post的时候axios.post(url,{a:1,b:2})，第二个参数是对象的时候，默认是这个类型


        Content-Type: application/x-www-form-urlencoded
        对于axios，post的时候let data = {a:1,b:2}; axios.post(url,qs.stringify({ data }))，第二个参数是字符串的时候，默认是这个类型


        Content-Type: multipart/form-data
        对于axios，post的时候let data = new FormData(); data.append('a',1'); data.append('b',2); axios.post(url,data)，参数是formData类型的时候，默认是这个类型，如果用form自带的action提交，默认是这个类型

      */

      // "Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
      // "Content-Type": "application/json"
    }
})

//添加请求拦截器
axiosInstance.interceptors.request.use(
    config => {
        // 在发送请求之前做些什么(可以在这里给头部添加token)
        if (config.type == 'change') {
          config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
        } else {
          config.headers['Content-Type'] = 'application/json'
        }
        if(sessionStorage.getItem('token')){
            config.headers.authorization = sessionStorage.getItem('token')
        }
        return config;
    },
    error => {
        //请求错误处理
        console.log(error)
        return Promise.reject(error);
    }
);

//添加相应拦截器
axiosInstance.interceptors.response.use(
    response => {
        console.log('axios响应拦截器的数据',response);
        /**
         *  对响应数据判断:
         *  如果成功返回数据，就通过return把数据返出去
         *  如果请求不成功，就在拦截器这里统一处理（组件的代码就不用关注错误的情况了）
         */
        if(response.status == 200){
            return response.data;
        }else{
          console.log(response.data,1212)
            handleErrorData(response.data);
        }
        return response;
    },
    err => {
      console.log(err.response.status,'errrrrr')
        // 对响应错误做点什么
        // console.log("axios响应拦截器的错误数据：",error);
        // Message.error(error.message);
        if(err.response.status === 401){
          // const data = { code: -1, message: '登录超时'};
          Message.error("登录超时，请重新登录!");
          location.href="#/login";
        }else{
          return Promise.reject(error);
        }

    }
);
//对错误信息的处理函数
function handleErrorData(errMes){
    if(errMes.message){
        Message.error(errMes.message);
      }else{
        switch(errMes.code){
          case 401 :
            Message.error("未授权，请重新登录!");
            break;
          case 403 :
            Message.error("拒绝访问");
            break;
          case 404 :
            Message.error("很抱歉，资源未找到!");
            break;
          case 500 :
            Message.error("服务器错误!");
            break;
          case 504 :
            Message.error("网络超时!");
            break;
          default :
            Message.error("服务正在联调中，请稍后!");
            break;
        }
    }
}

export {axiosInstance}
