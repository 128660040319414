// const baseURL = 'http://localhost:3000'
const baseURL = 'https://www.qingblog.cn'
const api ={
    //图片上传
    upload: baseURL + '/api/uploads',
    //登录接口
    login:baseURL + '/api/login',
    //注册接口
    register:baseURL + '/api/register',
    //获取个人信息
    userInfo:baseURL + '/api/userInfo',
    //发布文章
    addArticle:baseURL + '/api/addArticle',
    //文章列表
    articleList:baseURL + '/api/articleList',
    //热门文章列表
    hotList:baseURL + '/api/hotList',
    //文章详情
    articleDetails: baseURL + '/api/articleDetails',
}

module.exports =  {
    api,
    baseURL
};
