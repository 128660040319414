import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/styles/reset.css'
//css3
import 'animate.css' 
import './assets/font/iconfont.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//粒子效果
import VueStarrySky from "vue-starry-sky"
import "vue-starry-sky/lib/vue-starry-sky.css"

Vue.use(VueStarrySky);
//  轮播
// import Vue from 'vue';
// import Carousel3d from 'vue-carousel-3d';

// pdf






// Vue.use(Carousel3d);
//编辑器
import VueQuillEditor from 'vue-quill-editor'
//编辑器 require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
//axios
import {get,post,axiosDelete,put} from '@/utils/require.js'
Vue.prototype.$axiosGet = get;
Vue.prototype.$axiosPost = post;
Vue.prototype.$axiosDelete = axiosDelete;
Vue.prototype.$axiosPut = put;

 
Vue.use(VueQuillEditor, /* { default global options } */)
Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
