import { axiosInstance as axios } from "./axios.js";
//get
export function get(url,params={}){
    return axios({
        //这里的 axios 就是从 axios.js 中引入的 axiosInstance
        url:url,
        method:'get',
        params
    })
}

//post
export function post(url,params={}){
    return axios({
        //这里的 axios 就是从 axios.js 中引入的 axiosInstance
        url:url,
        method:'post',
        data:params
    })
}
//delete
export function axiosDelete(url,params={}){
    return axios({
        //这里的 axios 就是从 axios.js 中引入的 axiosInstance
        url:url,
        method:'delete',
        data:params
    })
}
//put
export function put(url,params={}){
    return axios({
        //这里的 axios 就是从 axios.js 中引入的 axiosInstance
        url:url,
        method:'put',
        data:params
    })
}