import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import { api, baseURL} from '@/utils/api'
import { get, post } from '@/utils/require'
import router from '../router';
export default new Vuex.Store({
  state: {
      userInfo:{},
      baseUrl:'https://www.qingblog.cn/'
  },
  // getters 只会依赖 state 中的成员去更新
  getters: {

  },
  mutations: {
    loginUserInfo:function(state){
        get(api.userInfo,{}).then(res => {
            state.userInfo = res.data;
            state.userInfo.photo = state.userInfo.photo.split('/')[1];
            // localStorage.setItem('userInfo',JSON.stringify(data));
            router.push('/articleList');
        }).catch(err => {
            console.log(err,'err')
        })
        // router.push('/');
        // console.log(state.userInfo,'vuex')
    },
    getUserInfo:function(state){
        // console.log(this.commit('handleUserName'))
        get(api.userInfo,{}).then(res => {
            // console.log(res.data,'res')
            // this.commit('handleUserName',res.data);
            state.userInfo = res.data;
            state.userInfo.photo = state.userInfo.photo.split('/')[1];
            // this.userInfo = this.$store.state.userInfo.username;
            // console.log(this.$store.state.userInfo.username,this.isShow)
        }).catch(err => {
            console.log(err,'err')
        })
    }
  },
  actions: {
  },
  modules: {
  }
})
